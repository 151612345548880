<template>
    <div class="bg-white shadow-2xl flex w-full"
         :class="[
            isIndex ? 'flex-row items-center p-2 gap-2' : 'px-8 py-3 flex-col justify-around mx-auto',
            product.sku === searchTerm ? 'border-4 border-frontend-brand/50 scale-[1.02] transition-all duration-300' : ''
        ]">
        <div v-if="product.sku === searchTerm" class="absolute top-2 right-2 bg-frontend-brand text-white text-xs px-2 py-1 rounded">
            Exact SKU Match
        </div>
        <div v-if="hasImage" :class="isIndex? 'w-1/2' : 'w-full'">
            <Link :href="route('products.show', product.slug)">
                <img :src="product.photo_url" :alt="product.name" class="w-[150px] object-fill mx-auto" />
            </Link>
        </div>
        <div class="flex flex-col w-full justify-between h-full" :class="(isIndex? '' : 'text-center ' + (hasImage || isReplacement? '' : 'mt-10') )">
            <div v-if="isIndex" class="flex flex-row justify-between items-center w-full">
                <p v-if="product.machine_part_type" class="font-semibold p-2 text-sm" :class="getCategoryText() + ' ' + getCategoryBg()">
                    {{ product.machine_part_type.toUpperCase() }}
                </p>
                <div v-if="product.is_purchaseable && !isIndex" class="font-semibold text-black">
                    <p v-if="isDiscounted" class="line-through text-xs text-gray-600"> {{ getFriendlyPrice(product) }}</p>
                    <p>{{ getFriendlyDiscountedPrice(product) }}</p>
                </div>
            </div>
            <p class="text-lg font-bold text-black break-words">
                {{ product.name }}
            </p>
            <p v-if="product.is_purchaseable" class="text-frontend-brand font-semibold mt-1">
                {{ product.sku }}
            </p>
            <Link v-if="product.is_purchaseable && !isReplacement" :href="route('products.show', product.slug)" class="text-frontend-brand hover:text-frontend-brand/80 text-xs font-semibold" :class="!product.photo_url && !isIndex ? 'mt-12' : ''">
                View Details
            </Link>
            <div v-if="product.is_purchaseable && !isIndex" class="mt-3 font-semibold text-black">
                <p v-if="isDiscounted" class="line-through text-xs text-gray-600"> {{ getFriendlyPrice(product) }}</p>
                <p>{{ getFriendlyDiscountedPrice(product) }}</p>
            </div>
            <div class="flex" :class="isIndex? 'flex-row gap-2 justify-between mt-2' : 'flex-col'">
                <div v-if="product.is_purchaseable && !isReplacement" class="flex flex-row gap-2 items-center mx-auto w-full justify-center" :class="!hasImage ? 'justify-self-start' : ''">
                    <button @click="decreaseQuantity" class="bg-white border border-frontend-brand text-frontend-brand p-1 px-[9px] rounded-md hover:bg-frontend-brand/10 active:scale-110 transition transform duration-150">
                        <Minus class="w-2" />
                    </button>
                    <p class="text-black font-semibold text-xl w-6 text-center">
                        {{ quantity }}
                    </p>
                    <button @click="increaseQuantity" class="bg-white border border-frontend-brand text-frontend-brand p-1.5 px-2.5 rounded-md hover:bg-frontend-brand/10 active:scale-110 transition transform duration-150">
                        <Plus class="w-2" />
                    </button>
                </div>
                <div v-else class="mx-auto w-full">

                </div>
                <button v-if="product.is_purchaseable && !isReplacement" @click="updateCartItem(product, quantity)" class="bg-frontend-brand text-white p-2 hover:bg-frontend-brand/80 active:scale-105 transition transform duration-150" :class="isIndex? 'min-w-40' : 'mt-2'">
                    Add to Cart
                </button>
                <Link v-if="!product.is_purchaseable" :href="route('products.show', product.slug)" class="bg-frontend-brand hover:bg-frontend-brand/80 text-white p-2 text-center" :class="isIndex? 'min-w-40': 'mt-2 '">
                    View Details
                </Link>

                <p v-if="!isIndex && product.is_purchaseable" class="text-center mt-2 font-semibold text-black">
                    Stock: <span class="font-black" :class="product.stock_level.colour === 'success' ? 'text-green-500' : 'text-yellow-600'">{{ product.stock_level.message }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {updateCartItem} from '@/util/cart';
import {ref} from 'vue';
import Minus from '@/Components/Frontend/Svg/Minus.vue';
import Plus from '@/Components/Frontend/Svg/Plus.vue';
import type {Product} from '@/util/product';
import {useCartStore} from '@/stores/CartStore';
import {getFriendlyDiscountedPrice, getFriendlyPrice} from '@/util/vat';
import {Link} from '@inertiajs/vue3';

const cart = useCartStore();
const quantity = ref(1);
const increaseQuantity = () => {
    quantity.value++;
};
const decreaseQuantity = () => {
    if (quantity.value > 1) quantity.value--;
};

const props = defineProps({
    isIndex: {
        type: Boolean,
        default: false
    },
    isReplacement: {
        type: Boolean,
        default: false
    },
    product: {
        type: Object as () => Product,
        required: true
    },
    searchTerm: {
        type: String,
        required: false
    }
});
const isDiscounted = props.product.formatted_pricing.discounted_pricing.is_discounted;
const hasImage = props.product.photo_url !== null;

function getCategoryColor() {
    switch (props.product.machine_part_type) {
        case 'Accessories':
            return 'frontend-brand';
        case 'Oils & Lubricants':
            return 'green-500';
        case 'Service Kits':
            return 'blue-500';
        case 'Merchandise':
            return 'purple-500';
    }
}

function getCategoryText() {
    return 'text-' + getCategoryColor();
}

function getCategoryBg() {
    return 'bg-' + getCategoryColor() + '/20';
}
</script>
